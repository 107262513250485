<template>
  <PageDefault :isLoading="isLoading" :order="order">
    <template v-slot:navbar-brand>
      <div class="navbar-item">
        <a>
          <img :src="$theme().image" :alt="$theme().name" />
        </a>
      </div>
    </template>

    <template v-slot:content>
      <div class="annotation">
        <translate>Pedido reservado hasta:</translate>
        {{ order.expiration | formatDateTimeHuman }}
        <p>
          <translate>URL de confirmación:</translate>
          <a :href="confirmOrderURL">{{ confirmOrderURL }}</a>
        </p>
        <p v-translate>
          Reserva realizada, ya puedes cerrar esta ventana. Para proceder con otra
          reserva, inicia un nuevo proceso de reserva desde el box.
        </p>
      </div>

      <h3 v-translate>Editar reserva</h3>
      <div class="card">
        <div class="card-content">
          <div class="column columns is-multiline">
            <div class="column is-6">
              <FieldInput
                v-model="order.expiration_local_format"
                :errors="errors.expiration"
                label="Fecha expiración"
                name="expiration_local_format"
              />
            </div>
            <div class="column is-12">
              <FieldInput
                ref="email"
                :errors="errors.email"
                :focus="true"
                name="email"
                autocomplete="email"
                v-model="order.email"
                type="email"
                :label="strings['Email']"
              />
            </div>
            <div class="column is-5">
              <FieldInput
                ref="name"
                :errors="errors.name"
                name="name"
                autocomplete="given-name"
                v-model="order.name"
                :label="strings['Nombre']"
              />
            </div>
            <div class="column is-7">
              <FieldInput
                ref="surname"
                :errors="errors.surname"
                autocomplete="family-name"
                name="surname"
                v-model="order.surname"
                :label="strings['Apellidos']"
              />
            </div>
            <div class="column is-7">
              <FieldInput
                ref="phone"
                :errors="errors.phone"
                autocomplete="tel"
                name="phone"
                type="tel"
                v-model="order.phone"
                :label="strings['Teléfono']"
              />
            </div>
            <div class="column is-5">
              <FieldInput
                ref="postal_code"
                :errors="errors.postal_code"
                autocomplete="postal-code"
                name="postal_code"
                type="text"
                pattern="[0-9]*"
                v-model="order.postal_code"
                :label="strings['Código Postal']"
              />
            </div>
          </div>
          <button class="button is-info" @click="updateOrder()">Guardar</button>
        </div>
      </div>

      <MultipassOrderSummary class="order-summary" :order="order" />
    </template>
  </PageDefault>
</template>

<script>
import MultipassOrderMixin from "@/mixins/MultipassOrderMixin"
import filters from "@/filters"
import MultipassOrderSummary from "@/components/multipass/MultipassOrderSummary"

export default {
  name: "MultipassOrderConfirm",
  filters: filters,
  components: {
    MultipassOrderSummary,
  },
  mixins: [MultipassOrderMixin],
  computed: {
    strings() {
      return {
        Email: this.$gettext("Email"),
        Nombre: this.$gettext("Nombre"),
        Apellidos: this.$gettext("Apellidos"),
        Teléfono: this.$gettext("Teléfono"),
        "Código Postal": this.$gettext("Código Postal"),
      }
    },
    isFree() {
      if (!this.order.tickets) {
        return false
      }
      return this.order.tickets.every((item) => item.is_free)
    },
    confirmOrderURL() {
      if (!this.order) {
        return false
      }

      const route = this.$router.resolve({
        name: "multipass-order-confirm",
        params: {
          lookup_ref: this.order.lookup_ref,
        },
      }).route.fullPath

      return `${window.location.origin}/#${route}`
    },
  },
  data() {
    return {
      errors: {},
      formData: {
        expiration: "",
      },
    }
  },
  methods: {
    getMultipassOrder() {
      this.$api
        .getReservedMultipassOrder(this.$route.params.lookup_ref)
        .then((response) => {
          this.order = response
        })
        .catch(() => {
          this.$router.push("/404")
        })
    },
    updateOrder() {
      const protectionToken = "p" in this.$route.query ? this.$route.query.p : null

      this.$api
        .updateMultipassOrder(this.order.lookup_ref, {
          protection_token: protectionToken,
          expiration: this.order.expiration_local_format,
          email: this.order.email,
          name: this.order.name,
          surname: this.order.surname,
          phone: this.order.phone,
          postal_code: this.order.postal_code,
        })
        .then(
          () => {
            this.getMultipassOrder()
            this.$store.dispatch(
              "ui/success",
              this.$gettext("Datos guardados con éxito")
            )
          },
          (error) => {
            if (error.response.status == 400) {
              this.errors = error.response.data.details
              this.$store.dispatch(
                "ui/error",
                this.$gettext("El formulario contiene errores")
              )
            }
            if (error.response.status == 403) {
              this.$store.dispatch(
                "ui/error",
                this.$gettext("No tienes permiso para realizar esta operación")
              )
            }
          }
        )
    },
  },
  watch: {
    "order.status"(value) {
      if (value == "finished") {
        this.$router.push({ name: "order-success", query: this.$defaultQuery() })
      } else if (value == "abandoned") {
        this.$router.push({ name: "order-expired", query: this.$defaultQuery() })
      }
    },
  },
}
</script>
<style lang="sass">
@import '@/assets/sass/_variables.sass'
@import '@/assets/sass/_responsive.sass'

.annotation
  background: rgba($info, .2)
  color: darken($info, 20%)
  font-weight: 500
  text-align: center
  margin: 1rem auto 1.5rem auto
  @include from($tablet)
    padding: 1.5rem .75rem
  @include until($tablet)
    padding: .75rem

.event-card
  flex-grow: 1

.payment-info
  background: $grey-lighter
  margin: 1.5rem auto

.order-confirm-grid
  margin-top: 2rem
  @include until($widescreen)
    .contact-data-form
      margin-top: 1.5rem
  @include from($widescreen)
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    & > *
      width: 50%

    .contact-data-form
      order: 0
      width: 100%
    .order-summary
      width: 100%
      order: 1
      font-size: .9rem
    .payment-info
      order: 3
    .order-confirm-actions
      order: 4
</style>
